import React from 'react'
import styled from 'styled-components'
import Typewriter from 'typewriter-effect';
import Button from './Button';
import { currency,token,addressToken,game,wiki,discord,twitter,instagram,facebook } from '../components/constants.ts';
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const Title = styled.h2`
font-size: ${props => props.theme.fontxxl};
text-transform: capitalize;
width: 80%;
color: ${props => props.theme.text};
align-self: flex-start;

span{
    text-transform: uppercase;
    font-family:'Akaya Telivigala', cursive;
}
.text-1{
    color : pink;
}
.text-2{
    color : #c0db73;
}
.text-3{
    color : #e5bce6;
}
.text-4{
    color : orange;
}

@media (max-width: 70em){
    font-size: ${props => props.theme.fontxl};
}
@media (max-width: 48em){
    align-self:center;
    text-align:center;
}
@media (max-width: 40em){
    width: 90%;

}
`

const Subtitle = styled.h3`
    font-size: ${(props) => props.theme.fontlg};
    text-transform: capitalize;
    color: ${props => `rgba(${props.theme.textRgba},0.6)`};
    font-weight:600;
    margin-bottom: 1rem;
    width: 80%;
    align-self: flex-start;

    @media (max-width: 40em){
    font-size: ${props => props.theme.fontmd};    
   
}

@media (max-width: 48em){
    align-self:center;
    text-align:center;
}

`

const Subtitle2 = styled.h3`
    font-size: ${(props) => props.theme.fontlg};
    text-transform: capitalize;
    color: orange;
    font-weight:600;
    margin-bottom: 1rem;
    width: 80%;
    align-self: flex-start;

    @media (max-width: 40em){
    font-size: ${props => props.theme.fontmd};    
   
}

@media (max-width: 48em){
    align-self:center;
    text-align:center;
}

`

const ButtonContainer = styled.div`
    width: 80%;
    align-self: flex-start;

    @media (max-width: 48em){
    align-self:center;
    text-align:center;

    button{
        margin: 0 auto;
    }
}
`

const Announcement = styled.div`
  background-color: #9fd9c1; /* Bleu clair pour contraster avec le fond marron */
  color: #2c3e50; /* Texte en bleu foncé pour une bonne lisibilité */
  padding: 1rem;
  border-left: 5px solid #2980b9; /* Bordure bleu plus foncé pour accentuer */
  font-size: ${(props) => props.theme.fontmd};
  margin-bottom: 1rem;
  width: 80%;
  align-self: flex-start;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 40em){
    font-size: ${(props) => props.theme.fontsm};    
  }

  @media (max-width: 48em){
    align-self: center;
    width: 100%;
  }

  a {
    color: #2980b9;
    text-decoration: underline;
  }
`;



const TypeWriterText = () => {
    const {t} = useTranslation();
    return (
        <>
        <Title>
        {t('WelcomeHome')}
        <br/>
        {t('WelcomeHome2')}
            <Typewriter options={{
                autoStart: true,
                loop: true
            }}
                onInit={(typewriter) => {
                    typewriter.typeString('<span class="text-1">Polygon P2E Game .</span>')
                        .pauseFor()
                        .deleteAll()
                        .typeString('<span class="text-2">ROI FAST !</span>')
                        .pauseFor()
                        .deleteAll()
                        .typeString('<span class="text-3">Several seasons.</span> ')
                        .pauseFor()
                        .deleteAll()
                        .typeString('<span class="text-4">Token reward.</span> ')
                        .pauseFor()
                        .deleteAll()
                        .start()
                }}
            />
            </Title>
            <Subtitle>{t('WelcomeHome3')}</Subtitle>
            <Announcement>
        <strong>Game Release soon !! </strong> 
      </Announcement>
            <Announcement>
        <strong>$TCF Token :</strong> {addressToken}
      </Announcement>
      <Announcement>
        Use <strong>Rabby Wallet</strong> For a better experience on Polygon blockchain : <a href="https://rabby.io/" target="_blank" rel="noopener noreferrer">https://rabby.io/</a>
      </Announcement>
            <ButtonContainer>
                <Button text="Whitepaper" link={wiki} />
            </ButtonContainer>
        
        </>
    )
}

export default TypeWriterText