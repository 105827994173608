export const currency: string ="POL";
export const token: string ="TCK";
export const addressToken: string ="Token will be released soon";                                    
export const game: string ="";
export const wiki: string ="https://whitepaper.catfarmcrypto.com/";
export const discord: string ="https://discord.gg/E9M8Rr8fgW";
export const twitter: string ="";
export const instagram: string ="";
export const facebook: string ="";
// import { currency,token,addressToken,game,wiki,discord,twitter,instagram,facebook } from '../../../constants';
