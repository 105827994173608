import React from 'react'
import styled from 'styled-components'
import GIF from '../assets/pixelchat2.gif'  


const VideoContainer = styled.div`
width:100%;


img{
    width:100%;
    height: auto;
    border-radius:20px;
}
@media (max-width: 64em){
  min-width: 40vh;
}
`

const CoverVideo = () => {
  return (
    <VideoContainer>
        {/* <video src={GIF} type="video/mp4" autoPlay muted loop/> */}
        <img border="0" class="fit-picture" src={GIF}></img>
    </VideoContainer>
  )
}

export default CoverVideo